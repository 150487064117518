div.welcome_screen_two{
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 90vh;
    min-height: 360px;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
}
div.welcome_text_two{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 30px 0 30px;
    text-align: left;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: nowrap;
}
div.usp_homebanner{
    width: 50%;
    padding: 0 30px 0 30px;
}
div.welcome_text_two>h2{
    height: 100%;
    width: 100%;
    font-size: var(--xxlarge-text);
    padding: 20px 0;
    margin: 20px;
    color: var(--gan-white);
    font-family: var(--font-heading-family);
    font-style: var(--font-heading-style);
    font-weight: var(--font-heading-weight);
}
a.welcome_cta_two{
    padding: 20px 80px;
    margin: 20px;
    background-color: var(--gan-white);
    color: var(--gan-black);
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
    text-decoration: none;
}
@media screen and (max-width: 980px){
    div.welcome_text_two>h2{
        font-size: var(--large-text);
    }
}
@media screen and (max-width: 680px){
  div.welcome_screen_two{
      align-items: flex-end;
      justify-content: center;
      align-content: center;
      padding-bottom: 5vh;
    }
    div.welcome_text_two{
      text-align: center;
      width: 100%;
      align-content: center;
      align-items: center;
    }
  	div.usp_homebanner{
      width: 100%;
    }
}
@media screen and (max-width: 620px){
    div.welcome_text_two>h2, a.welcome_cta_two {
        margin: 0px;
    }
}